import { useState } from "react";
import { commissionApi } from "../services/commission.ts";
import { useDispatch } from "react-redux";
import { addOpenCommission } from "../redux/commission.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenCommission = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getCommission = async () => {
    dispatch(setReduxLoading(true));
    try {
      // dispatch(startLoading());
      const commission = await commissionApi.getAllCommissions();
      dispatch(addOpenCommission(commission));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getCommission,
  };
};

export default useOpenCommission;
