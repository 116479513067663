import axiosClient from "../utils/axios.tsx";

class Teams {
  async getAllTeams(role) {
    try {
      const teams = await axiosClient().get(`users?role=${role}`);

      return teams.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const teamsApi = new Teams();
