import axiosClient from "../utils/axios.tsx";
import Cookies from "js-cookie";

class Activity {
  async getAllActivities() {
    const userId: number = Cookies.get("super-partner-user-id");
    try {
      const activity = await axiosClient().get(
        // "pipedrive-organisations/recent-activities"
        `pipedrive-organisations/super-partner/${userId}/recent-activities`,
      );

      return activity.data;
    } catch (error) {
      console.log("error =>", error);
    }
  }
}

export const activityApi = new Activity();
