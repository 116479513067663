import React from "react";
import { Typography } from "antd";

const StatusBtn = ({ item }): JSX.Element => {
  const { Text } = Typography;
  return (
    <Text
      style={
        item === "Churned" || item === "Disqualified"
          ? { backgroundColor: "#FEF3F2", color: "#B42318" }
          : item === "Lead"
            ? { backgroundColor: "#EFF4FF", color: "#0439a3" }
            : item === "In Pipeline"
              ? { backgroundColor: "#FFFAEB", color: "#B54708" }
              : item === "Customer"
                ? { backgroundColor: "#ECFDF3", color: "#027A48" }
                : { backgroundColor: "#FFFAEB", color: "#B54708" }
      }
      className="text-[14px] font-semibold capitalize w-auto rounded-[16px] p-1 px-3"
    >
      {item ? item : "No Status"}
    </Text>
  );
};

export default StatusBtn;
