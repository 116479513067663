// @ts-nocheck

import { createSlice } from "@reduxjs/toolkit";

interface Teams {
  admin: any[];
  employee: any[];
  affilliate: any[];
}

const initialState: Teams = {
  admin: [],
  affilliate: [],
  employee: [],
};

export const counterSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    getAdmins: (state, action) => {
      state.admin = action.payload;
    },
    addAffilliates: (state, action) => {
      state.affilliate = action.payload;
    },
    addEmployees: (state, action) => {
      state.employee = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { getAdmins, addAffilliates, addEmployees } = counterSlice.actions;

export default counterSlice.reducer;
