import React from "react";
import { Col, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function Booking({ isOpen, onClose }) {
  return (
    <Modal
      visible={isOpen}
      onCancel={onClose}
      footer={null}
      closeIcon={<CloseOutlined />}
      width={800}
      centered
      maskClosable={false}
    >
      <Col className="iframe m-5">
        <iframe src="https://calendly.com/d/ckjc-3xk-kh9/referral-partner-exploration-support-session?month=2024-07"></iframe>
      </Col>
    </Modal>
  );
}
