import React from "react";
import { Typography } from "antd";
import moment from "moment";

type itemProps = {
  icon: string;
  heading: string;
  name: string;
  date: string;
};

const ProfileSection = ({ icon, heading, name, date }: itemProps) => {
  const { Text } = Typography;

  return (
    <div className="flex items-center gap-4">
      <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100">
        <img
          src={icon ? icon : "/images/profile.svg"}
          alt=""
          className="object-cover w-full h-full"
        />
      </div>
      <div className="grid">
        <Text className="text-[#344054] text-[14px] font-medium font-sans">
          {heading}
        </Text>
        <Text className="text-[#475467] text-[14px] font-normal font-sans">
          Referred <span className="font-bold">{name}</span> on{" "}
          <span className="font-bold">{moment(date).format("DD/MM/YYYY")}</span>
        </Text>
      </div>
    </div>
  );
};

export default ProfileSection;
