import { useState } from "react";
import { payoutApi } from "../services/payouts.ts";
import { useDispatch } from "react-redux";
import { addOpenPayouts } from "../redux/payouts.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenPayouts = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getPayouts = async (user, values?) => {
    dispatch(setReduxLoading(true));
    try {
      // dispatch(startLoading());
      const payouts = await payoutApi.getAllPayouts(user, values);
      dispatch(addOpenPayouts(payouts));

      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getPayouts,
  };
};

export default useOpenPayouts;
