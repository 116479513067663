import { useState } from "react";
import { activityApi } from "../services/activity.ts";
import { useDispatch } from "react-redux";
import { addOpenActivity } from "../redux/activity.ts";
import { setReduxLoading } from "../redux/loading.ts";

const useOpenActivity = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const getActivity = async () => {
    dispatch(setReduxLoading(true));
    try {
      // dispatch(startLoading());
      const activity = await activityApi.getAllActivities();
      dispatch(addOpenActivity(activity));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    dispatch(setReduxLoading(false));
  };

  return {
    isLoading,
    getActivity,
  };
};

export default useOpenActivity;
