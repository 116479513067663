import { useEffect } from "react";
import { useAuthContext } from "../context/auth-context.tsx";
import { useNavigate, useLocation } from "react-router-dom";

export const GuestGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, isLoading } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate("/dashboard");
    } else {
      // Only remove cookies and redirect if it is a protected route
      const publicRoutes = [
        "/",
        "/signin",
        "/signup",
        "/verify-email",
        "/forget-password",
        "/check-email",
        "/new-password",
        "/successfully-reset",
        "/deactivated",
      ];
      if (!publicRoutes.includes(location.pathname)) {
        navigate("/signin");
      }
    }
  }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return null;
  }

  return !isAuthenticated ? children : null;
};
