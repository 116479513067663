import React, { useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";

function CountrySelector({ setPaymentData, paymentData }: any) {
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setPaymentData((prevState) => ({
      ...prevState,
      country: value.label,
    }));
  };

  return (
    <Select
      options={options}
      onChange={changeHandler}
      value={options?.find((option) => option?.label === paymentData?.country)}
      className="shadow-none bg-white   w-full  h-[40px] text-[#344054] font-semibold text-[14px] rounded-[12px]"
    />
  );
}

export default CountrySelector;
