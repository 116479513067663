import React from "react";
import { useNavigate } from "react-router-dom";
const Logo = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <img
      onClick={() => navigate("/dashboard")}
      src="https://ik.imagekit.io/8extk8sjo/Logo_New.svg?updatedAt=1709035687473"
      alt=""
      className="w-[180px] desktop cursor-pointer"
    />
  );
};

export default Logo;
