import React from "react";
import { Typography } from "antd";

type itemProps = {
  heading: string;
  subHeading: string;
  isShow?: boolean;
};
const Heading = ({ heading, subHeading, isShow }: itemProps) => {
  const { Text } = Typography;
  return (
    <div className="grid">
      <Text className="text-[#101828] text-[30px] font-semibold headingText">
        {heading}
      </Text>
      {!isShow && (
        <Text className="text-[#475467] text-[16px]">{subHeading}</Text>
      )}
    </div>
  );
};

export default Heading;
