import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/auth-context.tsx";

export const AuthGuard = (props) => {
  const { children } = props;
  const { isAuthenticated, isLoading } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!isLoading && !isAuthenticated) {
      navigate("/signin", { replace: true });
    } else if (isLoading) {
      // Set a timeout to redirect if loading takes too long
      timeoutId = setTimeout(() => {
        // console.log("Loading timeout reached. Redirecting to signin.");
        navigate("/signin", { replace: true });
      }, 2000); // 2 seconds timeout
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return null;
  }

  return isAuthenticated ? children : null;
};
