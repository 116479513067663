import React from "react";
import Main from "../../views/payouts/main.tsx";
import Header from "../../layout/header/index.tsx";
import MainPartner from "../../views/partner/main.tsx";
const Partners = () => {
  return (
    <>
      <Header />
      <MainPartner />
    </>
  );
};

export default Partners;
