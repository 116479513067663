import React from "react";
import { Col, Row, Typography } from "antd";
import { Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Arrow from "./../../../assets/images/arrows.png";
import { toast } from "react-toastify";
import axiosClient from "../../../utils/axios.tsx";

const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28 resetPassword">
          <div className=" w-[500px]">
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(1).svg?updatedAt=1709121905924"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 forgotText"
            >
              Forgot password?
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2 worries">
                No worries, we’ll send you reset instructions.
              </Text>
            </div>

            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                boxShadow: "0px 4px 8px -2px #1018281A",
                width: "440px",
                margin: "30px auto",
              }}
              className=" p-9 rounded-[12px] mt-8 resetBox"
            >
              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  axiosClient()
                    .get(
                      `/users/request-reset-password/super-partner/${values.email.toLocaleLowerCase()}`,
                    )
                    .then((res) => {
                      setSubmitting(false);
                      navigate("/check-email", {
                        state: {
                          email: values.email.toLocaleLowerCase(),
                          type: "RESET_PASSWORD",
                        },
                      });
                      toast.success("Email sent!");
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      toast.error(error?.response?.data?.message);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Email
                    </Title>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="signInput lowercase"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                    <Button
                      style={{ position: "relative", top: "4px" }}
                      type="primary"
                      htmlType="submit"
                      className="w-full h-[44px] mt-5 bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <Spin /> : "Reset password"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center mt-8 flex justify-center gap-2 items-center logBox">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                alt=""
                className="desktop"
              />
              <img src={Arrow} alt="" className="mobile" />
              <Text
                onClick={() => navigate("/signin")}
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] cursor-pointer font-semibold"
              >
                Back to log in
              </Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
