import { Input } from "antd";
import React from "react";

// Extend the LabelInputProps interface to include onClick
interface LabelInputProps {
  title?: string;
  placeholder?: string;
  className?: string;
  value?: string;
  type?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>; // Add the onClick handler type
}

const LabelInput: React.FC<LabelInputProps> = ({
  title,
  placeholder,
  value,
  className,
  type,
  onClick,
  ...rest
}) => {
  return (
    <>
      {title && <label>{title}</label>}
      <Input
        value={value}
        type={type}
        placeholder={placeholder}
        {...rest}
        className={className}
        onClick={onClick} // Pass the onClick handler
      />
    </>
  );
};

export default LabelInput;
